import React, { useState } from "react";
import "./slide.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { HiArrowCircleLeft, HiArrowCircleRight } from "react-icons/hi";
const images = [
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0003.jpeg?alt=media&token=9793bbb8-346e-4f6e-9bf4-1e23f832bf96' ,
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0002.jpeg?alt=media&token=947f43af-4db5-4a3f-b67d-520faf87a68f' ,
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F00012.jpeg?alt=media&token=0c3c4d74-b1fb-4274-b620-1c784fba0da4',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0001.jpeg?alt=media&token=de71b79b-be43-4e22-bcf6-8963e5525fd8',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0003.jpeg?alt=media&token=9793bbb8-346e-4f6e-9bf4-1e23f832bf96',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F114.jpg?alt=media&token=710a9666-dd3c-4313-9db2-4abc65fd1c4a',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F331.jpg?alt=media&token=16b75cc7-ad40-4d83-aa76-290087203993',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0003.jpeg?alt=media&token=9793bbb8-346e-4f6e-9bf4-1e23f832bf96' ,
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0002.jpeg?alt=media&token=947f43af-4db5-4a3f-b67d-520faf87a68f' ,
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F00012.jpeg?alt=media&token=0c3c4d74-b1fb-4274-b620-1c784fba0da4',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2Ffacebook-20150322-133736.png?alt=media&token=5ff393fc-96cc-4a56-9be9-368375f40856',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F221.jpg?alt=media&token=75a02718-dae5-4eed-b3d2-a8dd8eebbaa5',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0003.jpeg?alt=media&token=9793bbb8-346e-4f6e-9bf4-1e23f832bf96',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F00012.jpeg?alt=media&token=0c3c4d74-b1fb-4274-b620-1c784fba0da4'
];

function Slider() {
  const [data, setData] = useState({ img: "", i: 0 });

  const handleClick = (img, i) => {
    setData({ img, i });
  };

  const imgAction = (action) => {
    let i = data.i;
    if (action === "next-img") {
      setData({ img: images[i + 1], i: i + 1 });
    }
    if (action === "previous-img") {
      setData({ img: images[i - 1], i: i - 1 });
    }
    if (!action) {
      setData({ img: "", i: 0 });
    }
  };

  return (
    <>
      {data.img && (
        <div className="w-screen">
          <div
            style={{
              width: "80%",
              height: "80vh",
              // background: "#fff",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              marginTop: "-300px",
              zIndex: 200,
            }}
          >
            <AiOutlineCloseCircle
              onClick={() => imgAction()}
              className="cancles"
              class="cursor-pointer"
              style={{
                position: "absolute",
                top: "10px",
                color: "white",
                // background: "white",
                fontSize: 40,
                borderRadius: 40,
                marginLeft: "80%",
              }}
            />

            <HiArrowCircleLeft
                          class="cursor-pointer"
              style={{ color: "white", fontSize: 40 }}
              onClick={() => imgAction("previous-img")}
            />
            <img className="images" src={data.img} alt="" />
            <HiArrowCircleRight
                          class="cursor-pointer"
              style={{ color: "white", fontSize: 40 }}
              onClick={() => imgAction("next-img")}
            />
            <h1
              style={{
                marginTop: "470px",
                position: "absolute",
              }}
            >
              <a style={{ color: "cyan", textDecorationLine: "none" }} href="#">
                See more in Gallery
              </a>
            </h1>
          </div>
        </div>
      )}
      <section className="liders">
        <div className="tracks">
          <div className="slides">
            {images.map((image, i) => (
              <img
                className="minis"
                key={i}
                src={image}
                onClick={() => handleClick(image, i)}
                alt=""
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Slider;