import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ds from "./img/DSC_0296.png"
import ds1 from "./img/dsc.png"
import ftt from  "./img/image140qw.png"
import holy from "../Components/banner/holy.jpg"



const His =()=>{
    return(
      
      
      <motion.nav
      className="md:pl-20 md:pr-20"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 2,
        delay: 0.3,
        ease: [0, 0.71, 0.2, 1.01]
      }}
      >
        <div className="w-[100%] text-center mt-5 md:p-5 flex justify-center">
          <img  src="https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2Fo.jpg?alt=media&token=d3b0b277-58f8-42b3-a3e3-3b6c9438b556" style={{ width: '85%', marginTop: 0    }}/>
              {/* <div className="w-screen"> */}
        </div>
        <div className="md:pl-20 md:pr-20">
       
    

<h1 id="2" style={{fontSize:40, color: "#b30000", fontWeight: "bold", paddingTop: 26 }}  className=" text-center"></h1>



{/* <STV2 /> */}

{/* <div className="relative">
 <div className='bg-white'>
      <div style={{width:"100%",}} className='bg-gradient-to-b opacity-90 from-white via-slate-50 md:pt-32 text-5xl to- text-center absolute p-2 z-20 w-[390px] pt-10 md:w-[] top-[] md:top-[] md:h-[600px] h-40'><span style={{fontSize:35, color: "#b30000", fontWeight: "bold", paddingTop: 30 }}  className="text-center md:mt-28 mt-20">THE KING OF KINGS AND LORD OF LORDS</span></div>
           <div
             // style={{width: '100%'}}
             dangerouslySetInnerHTML={{
               __html: `<video  style="
               position: ;
               width: 100%;
               top: 0;
               // height: 100%;
               object-fit: cover;
               // z-index: -1;" 
               autoplay loop muted playsinline>
               <source src=${play} type="video/mp4" />
               Your browser does not support the video tag.
               </video>`
              }}
              />
        <div style={{width:"100%",}} className='bg-gradient-to-t from-white via-slate-50 to- sm:bottom-[90px] mb-96 w-[390px] md:w-[] absolute top-[80px] md:top-[550px] h-36 md:h-48 
          '></div>
    </div> 
  </div> */}

  {/* // */}

  <div className="mt-24">
    <div className="shadow-lg lg:flex items-center justify-center mt-28">
      <img src={holy} alt="" className=" lg:h-[400px] md:m-2 w-80 ml-5 lg:w-[35%]"/>
            <h6 style={{ color: "#333", padding: 30, }}  className="lg:w-[50%] text-lg">
              <p className="p-2">
            The physical investiture of His Holiness Olumba Olumba Obu was on the 3rd of November, 1991, by the Sole Spiritual Head, Father Olumba Olumba Obu, as the king of Kings and Lord of Lords, according to predestination, and he is arrayed in Red garment-the symbol of his divine royalty and authority.
              </p>
            <p className="p-2 mt-2">
             Named after his Father in line with the rendition of the Biakpan people, His Holiness, first son of Leader Olumba Olumba Obu, had a fascinating childhood.
Destiny and time have played a tremendous role in the revelation of His Holiness Olumba Olumba Obu united with the Father and with complete harmony with the Father, he has been identified as a man of un-quantifiable love, mercy, humility, fatherly compassion and benevolence, and crown fitted him from the foundation of the world.
            </p>
</h6>
    </div>
       
       <div className="shadow- p-2 lg:flex items-center mt-20">
            <h6 style={{ color: "#333", padding: 30, }}  className="lg:w-[50%] items-center text-lg">
              <p className="mt-2 p-2">
            His elevation and glorification was unprecedented in human history. Revealed and preordained to rule over all human beings, animals, birds, fishes, trees and other celestial and terrestrial creation, on 14th April 2000, His Holiness Olumba Olumba Obu was coronated as the king of Kings and Lord of Lords. Alongside his coronation, he was made the chairman of the Brotherhood Executive. He occupies the highest point in the administrative structure or hierarchy of Brotherhood of the Cross and Star.
              </p>
  <img src={ds1} alt="" className=" w-80 ml-5 lg:w-[80%] mt-5" />
  </h6> 
  <h6 style={{ color: "#333", padding: 30, }}  className="lg:w-[50%] lg:flex flex-col-reverse items-start text-lg">
  <p className="p-2 mt-2">
<p className="mt-2 p-2 items-center">
As His Holiness Olumba Olumba Obu remains eternally responsible to the Father and takes instructions, directives, blessings and strength from the Father, (because He and the Father eternally are inseparably one) all men take instructions and directives from Him. 
</p>
<p className="mt-2 p-2">
All decisions, policies, decrees, edicts and orders for the day to day administration of the kingdom emanate from Him – the first begotten of God – His Holiness Olumba Olumba Obu for he has been given a kingdom.
“I saw in a night visions, and, behold, one like the son of man with the clouds of heaven,
and came to the Ancients of days, and they brought him near before him.
</p>
  </p>
  {/* <img src={ds1} alt="" className=" w-80 ml-5 lg:w-96" /> */}
<p className="p-2 mt-2">
And there was given him dominion, and glory and a kingdom, that all people, nations and languages, should serve him;
his dominion is an everlasting dominion, which shall not pass away and his kingdom that which shalt not be destroyed” (Daniel 7:13-14)
</p>
</h6> 
       </div>

       <div className="shadow-lg flex flex-col lg:flex-row mt-20 mb-28">
            <h6 style={{ color: "#333", padding: 30, }}  className="lg:w-[50%]">
              <p className="mt-2 p-2">
            The mandatory growth and expansion of the kingdom of Brotherhood of the Cross and Star is squarely on his shoulders. He appoints, choose, aerobes ordains, exalts, promotes, post out or withdraws anybody from stations. He has absolute power as given to him of the father. He sees to the welfare of the entire Missionaries of BCS.
              </p>
              <p className="mt-2 p-2">
Authority and power have been given unto him. Judgment and Rulership have been given to him of the father, and all spirits and men must worship him, for the kingdom of this world has become the kingdom of Jehovah God his Christ (Rev 11:15). His signature Is the only signature and seal of the new kingdom of God now on earth- Brotherhood of the cross and star.
</p>
<img src={ds} alt="" className="w-[90%] mt-10" />
</h6>
            <h6 style={{ color: "#333", padding: 30, }}  className=" lg:w-[50%] ">
            <p className="mt-2 p-2">
            His Holiness Olumba Olumba Obu, The King of Kings and Lord of Lords, is the Chairman, Brotherhood of the Cross and Star worldwide. Leader Universal Theocratic/Head of Administration and Sole Signatory, BCS Worldwide. A position conferred on Him by Leader Olumba Olumba Obu, in fulfilment of the scriptures in John 17:1-5, Hebrews 1:6-9, John 5:19-30. His Holiness Olumba Olumba Obu is empowered to judge, promote, appoint and designate members into any position in the fold and also demote or dismiss whosoever He considers unfit.
            </p>
            <p className="mt-2 p-2">
             He can take necessary decisions for the progress and well-being of the Brotherhood Kingdom. It is an indisputable spiritual fact that the Sole Spiritual Head of the universe, Leader Olumba Olumba Obu and The King of Kings and Lord of Lords, His Holiness Olumba Olumba Obu are one inseparable entity. Although they are two physical personalities (The Father and The Son), they are one. In the Father is found the Son, and in the Son, The Father richly dwells; and through Him (The Son), The Father administers the Kingdom. (St. John 10:30).
             </p>
             <p className="mt-2 p-2">
“The first time he came, He was called Jesus and things were not like this. He never really showed himself but was humble even unto death. The Father raised Him up and gave Him a name which is above all other names; which name has he been given now? He is The King of Kings and Lord of Lords. He is also the first Son of God.
When you see Him, you see the Father, when you see the Father, you see the Son and we are all His children. As I taught you love, once you love Him, you are one with Him. No more male or female in Him. He has come to make us one. 
</p>
<p className="mt-2 p-2">
I am not the one called King of Kings; He is the King of Kings. Nobody else can answer that name. Do you know what the King of Kings and Lord of Lords means? Refrain from sin, do that which is good and have love, and you will see His glory” - Leader Olumba Olumba Obu.
</p>
</h6>

       </div>
  </div>

<div class="m-5 text-center items-center">
<Link 
   onClick={() => {
    window.scrollTo(0, 0);
  }}  
 role='button' to='/Doctrine' class="text-white text-center bg-red-600 shadow-lg hover:shadow-2xl px-4 py-2 rounded-md hover:bg-blue-500">Learn More</Link>
</div>

        </div>
        </motion.nav>

    )
}

export default His;