import React, { useState, useEffect, useRef } from "react";
import {
  PlusOutlined,
  UploadOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
  Switch,
  Radio,
  Upload,
  AutoComplete,
  Collapse,
  Modal,
} from "antd";
import {
  auth,
  db,
  createUserWithEmailAndPassword,
  setDoc,
  doc,
  collection,
  getDocs,
  storage,
  getDownloadURL,
  ref,
  uploadBytes,
  getDoc,
} from "../firebase";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { toast } from "react-toastify";

const { Option } = Select;

const Register = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isStakeholder, setIsStakeholder] = useState(false);
  const [isPriest, setIsPriest] = useState(false);
  const [isNot, setIsNot] = useState(false);
  const [isPosted, setIsPosted] = useState(null);
  const [qualifications, setQualifications] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categorie, setCategorie] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();
  const webcamRef = useRef(null);

   const [isMember, setIsMember] = useState(true);

  const handleChangeM = (value) => {
    setIsMember(value !== "Non Member");
  };

  //fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "BCS"));
        const uniqueCategories = new Set();
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.category) uniqueCategories.add(data.category);
        });
        setCategories(Array.from(uniqueCategories));
      } catch (error) {
        message.error("Failed to fetch categories");
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchCategorie = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "BCS"));
        const uniqueCategories = new Set();
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.department_name) uniqueCategories.add(data.department_name);
        });
        setCategorie(Array.from(uniqueCategories));
      } catch (error) {
        message.error("Failed to fetch department_name");
      }
    };
    fetchCategorie();
  }, []);

  //capture the photo or image of the user
  const capturePhoto = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCameraOpen(false);

    const blob = await fetch(imageSrc).then((res) => res.blob());
    setProfileImage(blob);

    const storageRef = ref(storage, `profile-images/${Date.now()}.jpg`);
    const snapshot = await uploadBytes(storageRef, blob);
    const url = await getDownloadURL(snapshot.ref);

    setImagePreview(url);
    form.setFieldsValue({ profile_image: url });
  };

  //submit user entreis to db
  const onFinish = async (values) => {
    if(!imagePreview){
      message.error("Please click the camera icon and take a passport photograph");
    }else{
      setLoading(true);
      try {
        const {
          email,
          password,
          first_name,
          middle_name,
          last_name,
          office,
          pew,
          gender,
          country,
          village,
          country_of_residence,
          country_of_origin,
          next_of_kin1,
          bethel,
          is_stakeholder,
          is_priest,
          pew_position,
          posted,
          station_posted_to,
          previous_stations,
          officer_at,
          year_assumed,
          position,
          qualifications,
          qualificationsList,
          qualification_documents,
          next_of_kin,
          means_of_identification,
          lga_of_origin,
          nation_of_worship,
          residential_address,
          phone_number,
          home_town,
          residing_state,
          state_of_origin,
          profile_image,
        } = values;
    
        // Check if the email already exists
        const userSnapshot = await getDoc(doc(db, "members", email));
        if (userSnapshot.exists()) {
          toast.error("A user with this email already exists!");
          setLoading(false);
          return;
        }
    
        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
    
        let documentImageUrl = profile_image || null;
        let profileImageUrl = profile_image;
    
        if (uploadedFile) {
          const storageRef = ref(
            storage,
            `users/${user.uid}/document_image/${uploadedFile.name}`
          );
          await uploadBytes(storageRef, uploadedFile);
          documentImageUrl = await getDownloadURL(storageRef);
        }
    
        if (profileImageUrl) {
          const profileImageRef = ref(
            storage,
            `users/${user.uid}/profile_image/${new Date().toISOString()}`
          );
          await uploadBytes(profileImageRef, profileImageUrl);
          profileImageUrl = await getDownloadURL(profileImageRef);
        }
    
        // Handle qualification documents upload if "Qualifications" is yes
        let uploadedQualificationDocuments = [];
        if (qualifications === "yes" && qualification_documents && qualification_documents.length > 0) {
          for (const doc of qualification_documents) {
            const qualificationRef = ref(
              storage,
              `users/${user.uid}/qualifications/${doc.name}`
            );
            await uploadBytes(qualificationRef, doc);
            const docUrl = await getDownloadURL(qualificationRef);
            uploadedQualificationDocuments.push(docUrl);
          }
        }
    
        // Build user data
        const userData = {
          uid: user.uid,
          email,
          bethel,
          first_name,
          middle_name,
          last_name,
          pew: pew,
          pew_position,
          gender,
          country,
          country_of_residence,
          country_of_origin,
          village,
          office: office || null,
          is_stakeholder,
          is_priest: isPriest,
          qualifications: qualifications === "yes" ? qualifications : null,
          qualificationsList: qualificationsList === "yes" ? qualificationsList : null,
          qualification_documents:
            qualifications === "yes" ? uploadedQualificationDocuments : null,
          next_of_kin: is_priest ? next_of_kin : null,
          posted: is_priest ? posted : null,
          station_posted_to:
            is_priest && posted === "yes" ? station_posted_to : null,
          previous_stations:
            is_priest && posted === "yes" ? previous_stations : null,
          residential_address,
          lga_of_origin,
          nation_of_worship,
          phone_number,
          home_town,
          residing_state,
          next_of_kin1,
          state_of_origin,
          officer_at:  officer_at || null,
          year_assumed: year_assumed || null,
          position: position || null,
          means_of_identification,
          document_image: documentImageUrl,
          profile_image: imagePreview,
        };
    
        // Filter out null or undefined values
        const filteredUserData = Object.fromEntries(
          Object.entries(userData).filter(
            ([_, value]) => value !== undefined && value !== null
          )
        );
    
        // Store user data in Firestore
        await setDoc(doc(db, "members", user.uid), filteredUserData);
    
        toast.success("Registration successful!");
        navigate(`/profile/${user.uid}`, { replace: true });
        window.location.reload();
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          toast.error("This email is already in use.");
        } else {
          toast.error("An error occurred: " + error.message);
        }
        setLoading(false);
      }
    }
  };
  
  //upload all images to the storge
  const uploadProps = {
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isSmallEnough = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        toast.error("You can only upload image files!");
      }
      if (!isSmallEnough) {
        toast.error("Image must be smaller than 2MB!");
      }
      setUploadedFile(isImage && isSmallEnough ? file : null);
      return false;
    },
  };

  return (
    <div className="p-5 max-w-4xl mx-auto">
      <div className="flex justify-center mb-5 lg:mt-10 mt-5">
        <div className="relative">
          <img
            src={
              imagePreview ||
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5seV9y_a20glyuiIKDENNpXYgWDuNFnQ5fIopKOrpsQ_ms8Kt33kZJSiS7m6JwbVIqfM&usqp=CAU"
            }
            alt="Profile Preview"
            className="w-40 h-40 rounded-full object-cover border-2 border-blue-500"
          />
          <Button
            type="primary"
            shape="circle"
            icon={<CameraOutlined color="#000" />}
            className="absolute bottom-5 right-0 bg-blue-500"
            onClick={() => setCameraOpen(true)}
            />
        </div>
      </div>
            <p className="text-center -mt-2 text-xs text-gray-500 mb-5">Click the camera icon to take a passport</p>

      <Modal
        visible={cameraOpen}
        title="Take a Photo"
        onCancel={() => setCameraOpen(false)}
        footer={null}
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          className="w-full rounded-md"
          mirrored
        />
        <Button type="primary" className="w-full mt-2 text-blue-600" onClick={capturePhoto}>
          Capture Photo
        </Button>
      </Modal>

      <Form
        form={form}
        className="text-center"
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Enter your Email" }]}
            >
              <Input className="h-12" placeholder="Please Enter your Email" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[{ required: true, message: "Your First Name" }]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter your First Name"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="middle_name"
              label="Middle Name"
              rules={[{ required: true, message: "Enter your Middle Name" }]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter your Middle Name"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[{ required: true, message: "Your Last Name" }]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter your Last Name"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="next_of_kin1"
              label="Next of kin"
              rules={[
                { required: true, message: "Enter your Next of kin" },
              ]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter your Next of kin"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[
                { required: true, message: "Enter your Phone Number" },
                {
                  pattern: /^\d{11}$/,
                  message: "Enter a valid 11-digit phone number",
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter your Phone Number" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="pew"
              label="Pew"
              rules={[{ required: true, message: "Please select Pew" }]}
            >
           <Select className="h-12" placeholder="Please choose" onChange={handleChangeM}>
                  <Option value="Non Member">Not Applicable </Option>
                  <Option value="Sabbath">Sabbath </Option>
                  <Option value="Congregation">Congregation</Option>
                  <Option value="Chorister">Chorister</Option>
                  <Option value="Spirited Child">Spirited Child</Option>
                  <Option value="Elder">Elder</Option>
                  <Option value="Ordained One">Ordained One</Option>
                  <Option value="Christ Natural Preacher">Christ Natural Preacher</Option>
                  <Option value="Christ Practical Student">Christ Practical Student</Option>
                  <Option value="Senior Christ Servant">Senior Christ Servant</Option>
                  <Option value="True Christ Witness">True Christ Witness</Option>
                  <Option value="Blessed Mother">Blessed Mother </Option>
                  <Option value="Divine Vanguard">Divine Vanguard </Option>
                  <Option value="Bishop">Bishop </Option>
                  <Option value="Christ Ambassador">Christ Ambassador </Option>
                  <Option value="Eminence">Eminence </Option>
                  <Option value="Archbishop">Archbishop </Option>
                  <Option value="Christ Shepherd">Christ Shepherd </Option>
                  <Option value="144,000 Virgin">144,000 Virgin </Option>
                </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true, message: "Please select gender" }]}
            >
              <Select className="h-12" placeholder="Please choose gender">
                <Option value="brother">Brother</Option>
                <Option value="sister">Sister</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Residential Address Section */}
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="residing_state"
          label="State of Residence"
          rules={[{ required: true, message: "Enter your State of Residence" }]}
        >
          <Input
            className="h-12"
            placeholder="Enter your State of Residence"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="residential_address"
          label="Residential Address"
          rules={[{ required: true, message: "Enter your Residential Address" }]}
        >
          <Input
            className="h-12"
            placeholder="Enter your Residential Address"
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
    <Col span={12}>
              <Form.Item name="country_of_residence" label="Country Of Residence" rules={[{required: true, message: 'Enter country', },]} >
              {/* <Input className='h-12' placeholder="Please Enter Your country" /> */}
              <Select className="h-12" placeholder="Please choose">
  <Option value="afghanistan">Afghanistan</Option>
  <Option value="albania">Albania</Option>
  <Option value="algeria">Algeria</Option>
  <Option value="andorra">Andorra</Option>
  <Option value="angola">Angola</Option>
  <Option value="antigua_and_barbuda">Antigua and Barbuda</Option>
  <Option value="argentina">Argentina</Option>
  <Option value="armenia">Armenia</Option>
  <Option value="australia">Australia</Option>
  <Option value="austria">Austria</Option>
  <Option value="azerbaijan">Azerbaijan</Option>
  <Option value="bahamas">Bahamas</Option>
  <Option value="bahrain">Bahrain</Option>
  <Option value="bangladesh">Bangladesh</Option>
  <Option value="barbados">Barbados</Option>
  <Option value="belarus">Belarus</Option>
  <Option value="belgium">Belgium</Option>
  <Option value="belize">Belize</Option>
  <Option value="benin">Benin</Option>
  <Option value="bhutan">Bhutan</Option>
  <Option value="bolivia">Bolivia</Option>
  <Option value="bosnia_and_herzegovina">Bosnia and Herzegovina</Option>
  <Option value="botswana">Botswana</Option>
  <Option value="brazil">Brazil</Option>
  <Option value="brunei">Brunei</Option>
  <Option value="bulgaria">Bulgaria</Option>
  <Option value="burkina_faso">Burkina Faso</Option>
  <Option value="burundi">Burundi</Option>
  <Option value="cabo_verde">Cabo Verde</Option>
  <Option value="cambodia">Cambodia</Option>
  <Option value="cameroon">Cameroon</Option>
  <Option value="canada">Canada</Option>
  <Option value="central_african_republic">Central African Republic</Option>
  <Option value="chad">Chad</Option>
  <Option value="chile">Chile</Option>
  <Option value="china">China</Option>
  <Option value="colombia">Colombia</Option>
  <Option value="comoros">Comoros</Option>
  <Option value="congo">Congo</Option>
  <Option value="costa_rica">Costa Rica</Option>
  <Option value="croatia">Croatia</Option>
  <Option value="cuba">Cuba</Option>
  <Option value="cyprus">Cyprus</Option>
  <Option value="czech_republic">Czech Republic</Option>
  <Option value="denmark">Denmark</Option>
  <Option value="djibouti">Djibouti</Option>
  <Option value="dominica">Dominica</Option>
  <Option value="dominican_republic">Dominican Republic</Option>
  <Option value="ecuador">Ecuador</Option>
  <Option value="egypt">Egypt</Option>
  <Option value="el_salvador">El Salvador</Option>
  <Option value="equatorial_guinea">Equatorial Guinea</Option>
  <Option value="eritrea">Eritrea</Option>
  <Option value="estonia">Estonia</Option>
  <Option value="eswatini">Eswatini</Option>
  <Option value="ethiopia">Ethiopia</Option>
  <Option value="fiji">Fiji</Option>
  <Option value="finland">Finland</Option>
  <Option value="france">France</Option>
  <Option value="gabon">Gabon</Option>
  <Option value="gambia">Gambia</Option>
  <Option value="georgia">Georgia</Option>
  <Option value="germany">Germany</Option>
  <Option value="ghana">Ghana</Option>
  <Option value="greece">Greece</Option>
  <Option value="grenada">Grenada</Option>
  <Option value="guatemala">Guatemala</Option>
  <Option value="guinea">Guinea</Option>
  <Option value="guinea_bissau">Guinea-Bissau</Option>
  <Option value="guyana">Guyana</Option>
  <Option value="haiti">Haiti</Option>
  <Option value="honduras">Honduras</Option>
  <Option value="hungary">Hungary</Option>
  <Option value="iceland">Iceland</Option>
  <Option value="india">India</Option>
  <Option value="indonesia">Indonesia</Option>
  <Option value="iran">Iran</Option>
  <Option value="iraq">Iraq</Option>
  <Option value="ireland">Ireland</Option>
  <Option value="israel">Israel</Option>
  <Option value="italy">Italy</Option>
  <Option value="jamaica">Jamaica</Option>
  <Option value="japan">Japan</Option>
  <Option value="jordan">Jordan</Option>
  <Option value="kazakhstan">Kazakhstan</Option>
  <Option value="kenya">Kenya</Option>
  <Option value="kiribati">Kiribati</Option>
  <Option value="korea_north">North Korea</Option>
  <Option value="korea_south">South Korea</Option>
  <Option value="kosovo">Kosovo</Option>
  <Option value="kuwait">Kuwait</Option>
  <Option value="kyrgyzstan">Kyrgyzstan</Option>
  <Option value="laos">Laos</Option>
  <Option value="latvia">Latvia</Option>
  <Option value="lebanon">Lebanon</Option>
  <Option value="lesotho">Lesotho</Option>
  <Option value="liberia">Liberia</Option>
  <Option value="libya">Libya</Option>
  <Option value="liechtenstein">Liechtenstein</Option>
  <Option value="lithuania">Lithuania</Option>
  <Option value="luxembourg">Luxembourg</Option>
  <Option value="madagascar">Madagascar</Option>
  <Option value="malawi">Malawi</Option>
  <Option value="malaysia">Malaysia</Option>
  <Option value="maldives">Maldives</Option>
  <Option value="mali">Mali</Option>
  <Option value="malta">Malta</Option>
  <Option value="marshall_islands">Marshall Islands</Option>
  <Option value="mauritania">Mauritania</Option>
  <Option value="mauritius">Mauritius</Option>
  <Option value="mexico">Mexico</Option>
  <Option value="micronesia">Micronesia</Option>
  <Option value="moldova">Moldova</Option>
  <Option value="monaco">Monaco</Option>
  <Option value="mongolia">Mongolia</Option>
  <Option value="montenegro">Montenegro</Option>
  <Option value="morocco">Morocco</Option>
  <Option value="mozambique">Mozambique</Option>
  <Option value="myanmar">Myanmar</Option>
  <Option value="namibia">Namibia</Option>
  <Option value="nauru">Nauru</Option>
  <Option value="nepal">Nepal</Option>
  <Option value="netherlands">Netherlands</Option>
  <Option value="new_zealand">New Zealand</Option>
  <Option value="nicaragua">Nicaragua</Option>
  <Option value="niger">Niger</Option>
  <Option value="nigeria">Nigeria</Option>
  <Option value="north_macedonia">North Macedonia</Option>
  <Option value="norway">Norway</Option>
  <Option value="oman">Oman</Option>
  <Option value="pakistan">Pakistan</Option>
  <Option value="palau">Palau</Option>
  <Option value="panama">Panama</Option>
  <Option value="papua_new_guinea">Papua New Guinea</Option>
  <Option value="paraguay">Paraguay</Option>
  <Option value="peru">Peru</Option>
  <Option value="philippines">Philippines</Option>
  <Option value="poland">Poland</Option>
  <Option value="portugal">Portugal</Option>
  <Option value="qatar">Qatar</Option>
  <Option value="romania">Romania</Option>
  <Option value="russia">Russia</Option>
  <Option value="rwanda">Rwanda</Option>
  <Option value="saint_kitts_and_nevis">Saint Kitts and Nevis</Option>
  <Option value="saint_lucia">Saint Lucia</Option>
  <Option value="saint_vincent_and_the_grenadines">Saint Vincent and the Grenadines</Option>
  <Option value="samoa">Samoa</Option>
  <Option value="san_marino">San Marino</Option>
  <Option value="sao_tome_and_principe">Sao Tome and Principe</Option>
  <Option value="saudi_arabia">Saudi Arabia</Option>
  <Option value="senegal">Senegal</Option>
  <Option value="serbia">Serbia</Option>
  <Option value="seychelles">Seychelles</Option>
  <Option value="sierra_leone">Sierra Leone</Option>
  <Option value="singapore">Singapore</Option>
  <Option value="slovakia">Slovakia</Option>
  <Option value="slovenia">Slovenia</Option>
  <Option value="solomon_islands">Solomon Islands</Option>
  <Option value="somalia">Somalia</Option>
  <Option value="south_africa">South Africa</Option>
  <Option value="south_sudan">South Sudan</Option>
  <Option value="spain">Spain</Option>
  <Option value="sri_lanka">Sri Lanka</Option>
  <Option value="sudan">Sudan</Option>
  <Option value="suriname">Suriname</Option>
  <Option value="sweden">Sweden</Option>
  <Option value="switzerland">Switzerland</Option>
  <Option value="syria">Syria</Option>
  <Option value="taiwan">Taiwan</Option>
  <Option value="tajikistan">Tajikistan</Option>
  <Option value="tanzania">Tanzania</Option>
  <Option value="thailand">Thailand</Option>
  <Option value="timor_leste">Timor-Leste</Option>
  <Option value="togo">Togo</Option>
  <Option value="tonga">Tonga</Option>
  <Option value="trinidad_and_tobago">Trinidad and Tobago</Option>
  <Option value="tunisia">Tunisia</Option>
  <Option value="turkey">Turkey</Option>
  <Option value="turkmenistan">Turkmenistan</Option>
  <Option value="tuvalu">Tuvalu</Option>
  <Option value="uganda">Uganda</Option>
  <Option value="ukraine">Ukraine</Option>
  <Option value="united_arab_emirates">United Arab Emirates</Option>
  <Option value="united_kingdom">United Kingdom</Option>
  <Option value="united_states">United States</Option>
  <Option value="uruguay">Uruguay</Option>
  <Option value="uzbekistan">Uzbekistan</Option>
  <Option value="vanuatu">Vanuatu</Option>
  <Option value="vatican">Vatican</Option>
  <Option value="venezuela">Venezuela</Option>
  <Option value="vietnam">Vietnam</Option>
  <Option value="yemen">Yemen</Option>
  <Option value="zambia">Zambia</Option>
  <Option value="zimbabwe">Zimbabwe</Option>
</Select>

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="country_of_origin" label="Country Of Origin" rules={[{required: true, message: 'Enter country', },]} >
              {/* <Input className='h-12' placeholder="Please Enter Your country" /> */}
              <Select className="h-12" placeholder="Please choose">
  <Option value="afghanistan">Afghanistan</Option>
  <Option value="albania">Albania</Option>
  <Option value="algeria">Algeria</Option>
  <Option value="andorra">Andorra</Option>
  <Option value="angola">Angola</Option>
  <Option value="antigua_and_barbuda">Antigua and Barbuda</Option>
  <Option value="argentina">Argentina</Option>
  <Option value="armenia">Armenia</Option>
  <Option value="australia">Australia</Option>
  <Option value="austria">Austria</Option>
  <Option value="azerbaijan">Azerbaijan</Option>
  <Option value="bahamas">Bahamas</Option>
  <Option value="bahrain">Bahrain</Option>
  <Option value="bangladesh">Bangladesh</Option>
  <Option value="barbados">Barbados</Option>
  <Option value="belarus">Belarus</Option>
  <Option value="belgium">Belgium</Option>
  <Option value="belize">Belize</Option>
  <Option value="benin">Benin</Option>
  <Option value="bhutan">Bhutan</Option>
  <Option value="bolivia">Bolivia</Option>
  <Option value="bosnia_and_herzegovina">Bosnia and Herzegovina</Option>
  <Option value="botswana">Botswana</Option>
  <Option value="brazil">Brazil</Option>
  <Option value="brunei">Brunei</Option>
  <Option value="bulgaria">Bulgaria</Option>
  <Option value="burkina_faso">Burkina Faso</Option>
  <Option value="burundi">Burundi</Option>
  <Option value="cabo_verde">Cabo Verde</Option>
  <Option value="cambodia">Cambodia</Option>
  <Option value="cameroon">Cameroon</Option>
  <Option value="canada">Canada</Option>
  <Option value="central_african_republic">Central African Republic</Option>
  <Option value="chad">Chad</Option>
  <Option value="chile">Chile</Option>
  <Option value="china">China</Option>
  <Option value="colombia">Colombia</Option>
  <Option value="comoros">Comoros</Option>
  <Option value="congo">Congo</Option>
  <Option value="costa_rica">Costa Rica</Option>
  <Option value="croatia">Croatia</Option>
  <Option value="cuba">Cuba</Option>
  <Option value="cyprus">Cyprus</Option>
  <Option value="czech_republic">Czech Republic</Option>
  <Option value="denmark">Denmark</Option>
  <Option value="djibouti">Djibouti</Option>
  <Option value="dominica">Dominica</Option>
  <Option value="dominican_republic">Dominican Republic</Option>
  <Option value="ecuador">Ecuador</Option>
  <Option value="egypt">Egypt</Option>
  <Option value="el_salvador">El Salvador</Option>
  <Option value="equatorial_guinea">Equatorial Guinea</Option>
  <Option value="eritrea">Eritrea</Option>
  <Option value="estonia">Estonia</Option>
  <Option value="eswatini">Eswatini</Option>
  <Option value="ethiopia">Ethiopia</Option>
  <Option value="fiji">Fiji</Option>
  <Option value="finland">Finland</Option>
  <Option value="france">France</Option>
  <Option value="gabon">Gabon</Option>
  <Option value="gambia">Gambia</Option>
  <Option value="georgia">Georgia</Option>
  <Option value="germany">Germany</Option>
  <Option value="ghana">Ghana</Option>
  <Option value="greece">Greece</Option>
  <Option value="grenada">Grenada</Option>
  <Option value="guatemala">Guatemala</Option>
  <Option value="guinea">Guinea</Option>
  <Option value="guinea_bissau">Guinea-Bissau</Option>
  <Option value="guyana">Guyana</Option>
  <Option value="haiti">Haiti</Option>
  <Option value="honduras">Honduras</Option>
  <Option value="hungary">Hungary</Option>
  <Option value="iceland">Iceland</Option>
  <Option value="india">India</Option>
  <Option value="indonesia">Indonesia</Option>
  <Option value="iran">Iran</Option>
  <Option value="iraq">Iraq</Option>
  <Option value="ireland">Ireland</Option>
  <Option value="israel">Israel</Option>
  <Option value="italy">Italy</Option>
  <Option value="jamaica">Jamaica</Option>
  <Option value="japan">Japan</Option>
  <Option value="jordan">Jordan</Option>
  <Option value="kazakhstan">Kazakhstan</Option>
  <Option value="kenya">Kenya</Option>
  <Option value="kiribati">Kiribati</Option>
  <Option value="korea_north">North Korea</Option>
  <Option value="korea_south">South Korea</Option>
  <Option value="kosovo">Kosovo</Option>
  <Option value="kuwait">Kuwait</Option>
  <Option value="kyrgyzstan">Kyrgyzstan</Option>
  <Option value="laos">Laos</Option>
  <Option value="latvia">Latvia</Option>
  <Option value="lebanon">Lebanon</Option>
  <Option value="lesotho">Lesotho</Option>
  <Option value="liberia">Liberia</Option>
  <Option value="libya">Libya</Option>
  <Option value="liechtenstein">Liechtenstein</Option>
  <Option value="lithuania">Lithuania</Option>
  <Option value="luxembourg">Luxembourg</Option>
  <Option value="madagascar">Madagascar</Option>
  <Option value="malawi">Malawi</Option>
  <Option value="malaysia">Malaysia</Option>
  <Option value="maldives">Maldives</Option>
  <Option value="mali">Mali</Option>
  <Option value="malta">Malta</Option>
  <Option value="marshall_islands">Marshall Islands</Option>
  <Option value="mauritania">Mauritania</Option>
  <Option value="mauritius">Mauritius</Option>
  <Option value="mexico">Mexico</Option>
  <Option value="micronesia">Micronesia</Option>
  <Option value="moldova">Moldova</Option>
  <Option value="monaco">Monaco</Option>
  <Option value="mongolia">Mongolia</Option>
  <Option value="montenegro">Montenegro</Option>
  <Option value="morocco">Morocco</Option>
  <Option value="mozambique">Mozambique</Option>
  <Option value="myanmar">Myanmar</Option>
  <Option value="namibia">Namibia</Option>
  <Option value="nauru">Nauru</Option>
  <Option value="nepal">Nepal</Option>
  <Option value="netherlands">Netherlands</Option>
  <Option value="new_zealand">New Zealand</Option>
  <Option value="nicaragua">Nicaragua</Option>
  <Option value="niger">Niger</Option>
  <Option value="nigeria">Nigeria</Option>
  <Option value="north_macedonia">North Macedonia</Option>
  <Option value="norway">Norway</Option>
  <Option value="oman">Oman</Option>
  <Option value="pakistan">Pakistan</Option>
  <Option value="palau">Palau</Option>
  <Option value="panama">Panama</Option>
  <Option value="papua_new_guinea">Papua New Guinea</Option>
  <Option value="paraguay">Paraguay</Option>
  <Option value="peru">Peru</Option>
  <Option value="philippines">Philippines</Option>
  <Option value="poland">Poland</Option>
  <Option value="portugal">Portugal</Option>
  <Option value="qatar">Qatar</Option>
  <Option value="romania">Romania</Option>
  <Option value="russia">Russia</Option>
  <Option value="rwanda">Rwanda</Option>
  <Option value="saint_kitts_and_nevis">Saint Kitts and Nevis</Option>
  <Option value="saint_lucia">Saint Lucia</Option>
  <Option value="saint_vincent_and_the_grenadines">Saint Vincent and the Grenadines</Option>
  <Option value="samoa">Samoa</Option>
  <Option value="san_marino">San Marino</Option>
  <Option value="sao_tome_and_principe">Sao Tome and Principe</Option>
  <Option value="saudi_arabia">Saudi Arabia</Option>
  <Option value="senegal">Senegal</Option>
  <Option value="serbia">Serbia</Option>
  <Option value="seychelles">Seychelles</Option>
  <Option value="sierra_leone">Sierra Leone</Option>
  <Option value="singapore">Singapore</Option>
  <Option value="slovakia">Slovakia</Option>
  <Option value="slovenia">Slovenia</Option>
  <Option value="solomon_islands">Solomon Islands</Option>
  <Option value="somalia">Somalia</Option>
  <Option value="south_africa">South Africa</Option>
  <Option value="south_sudan">South Sudan</Option>
  <Option value="spain">Spain</Option>
  <Option value="sri_lanka">Sri Lanka</Option>
  <Option value="sudan">Sudan</Option>
  <Option value="suriname">Suriname</Option>
  <Option value="sweden">Sweden</Option>
  <Option value="switzerland">Switzerland</Option>
  <Option value="syria">Syria</Option>
  <Option value="taiwan">Taiwan</Option>
  <Option value="tajikistan">Tajikistan</Option>
  <Option value="tanzania">Tanzania</Option>
  <Option value="thailand">Thailand</Option>
  <Option value="timor_leste">Timor-Leste</Option>
  <Option value="togo">Togo</Option>
  <Option value="tonga">Tonga</Option>
  <Option value="trinidad_and_tobago">Trinidad and Tobago</Option>
  <Option value="tunisia">Tunisia</Option>
  <Option value="turkey">Turkey</Option>
  <Option value="turkmenistan">Turkmenistan</Option>
  <Option value="tuvalu">Tuvalu</Option>
  <Option value="uganda">Uganda</Option>
  <Option value="ukraine">Ukraine</Option>
  <Option value="united_arab_emirates">United Arab Emirates</Option>
  <Option value="united_kingdom">United Kingdom</Option>
  <Option value="united_states">United States</Option>
  <Option value="uruguay">Uruguay</Option>
  <Option value="uzbekistan">Uzbekistan</Option>
  <Option value="vanuatu">Vanuatu</Option>
  <Option value="vatican">Vatican</Option>
  <Option value="venezuela">Venezuela</Option>
  <Option value="vietnam">Vietnam</Option>
  <Option value="yemen">Yemen</Option>
  <Option value="zambia">Zambia</Option>
  <Option value="zimbabwe">Zimbabwe</Option>
</Select>

              </Form.Item>
            </Col>
    </Row>

    {/* Origin Details Section */}
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="state_of_origin"
          label="State of Origin"
          rules={[{ required: true, message: "Enter your State of Origin" }]}
        >
          <Input
            className="h-12"
            placeholder="Enter your State of Origin"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="lga_of_origin"
          label="LGA of Origin"
          rules={[{ required: true, message: "Enter your LGA of Origin" }]}
        >
          <Input
            className="h-12"
            placeholder="Enter your LGA of Origin"
          />
        </Form.Item>
      </Col>
    </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="village"
              label="Village"
              rules={[
                { required: true, message: "Village" },
              ]}
            >
              <Input
                className="h-12"
                placeholder="Village"
              />
            </Form.Item>
          </Col>
{
  isMember === false ? null :
          <Col span={12}>
            <Form.Item
              name="nation_of_worship"
              label="CURRENT BCS NATION OF WORSHIP"
              rules={[{ required: true, message: "Enter Nation" }]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter Your Nation of worship"
              />
            </Form.Item>
          </Col>
}
        </Row>

     

        {/* <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="lga_of_origin"
              label="LOCAL GOVERNMENT AREA(LGA) OF ORGIN"
              rules={[
                { required: true, message: "Enter your State of Residence" },
              ]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter your (LGA) of ORGIN"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="home_town"
              label="Home Town"
              rules={[{ required: true, message: "Enter your Home Town" }]}
            >
              <Input
                className="h-12"
                placeholder="Please Enter yourHome Town"
              />
            </Form.Item>
          </Col>
        </Row> */}
{
  isMember === false ? null :
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="bethel"
              label="Bethel"
              rules={[{ required: true, message: "Enter Bethel" }]}
            >
              <Input className="h-12" placeholder="Please Enter Bethel" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="bethel_Location"
              label="Bethel Address"
              rules={[
                {
                  required: true,
                  message: "Please Enter Bethel Address",
                },
              ]}
            >
              <Input
                className="h-12"
                placeholder="Enter Bethel Address"
              />
            </Form.Item>
          </Col>
        </Row>
}

        <Row gutter={16}>
        {
  isMember === false ? null :
          <Col span={12}>
            <Form.Item
              name="bethel_country"
              label="Country where the bethel is located"
              rules={[
                { required: true, message: "Enter country where Bethel is located " },
              ]}
            >
              {/* <Input
                className="h-12"
                placeholder="Enter country where Bethel is located"
              /> */}
                     <Select className="h-12" placeholder="Please choose">
  <Option value="afghanistan">Afghanistan</Option>
  <Option value="albania">Albania</Option>
  <Option value="algeria">Algeria</Option>
  <Option value="andorra">Andorra</Option>
  <Option value="angola">Angola</Option>
  <Option value="antigua_and_barbuda">Antigua and Barbuda</Option>
  <Option value="argentina">Argentina</Option>
  <Option value="armenia">Armenia</Option>
  <Option value="australia">Australia</Option>
  <Option value="austria">Austria</Option>
  <Option value="azerbaijan">Azerbaijan</Option>
  <Option value="bahamas">Bahamas</Option>
  <Option value="bahrain">Bahrain</Option>
  <Option value="bangladesh">Bangladesh</Option>
  <Option value="barbados">Barbados</Option>
  <Option value="belarus">Belarus</Option>
  <Option value="belgium">Belgium</Option>
  <Option value="belize">Belize</Option>
  <Option value="benin">Benin</Option>
  <Option value="bhutan">Bhutan</Option>
  <Option value="bolivia">Bolivia</Option>
  <Option value="bosnia_and_herzegovina">Bosnia and Herzegovina</Option>
  <Option value="botswana">Botswana</Option>
  <Option value="brazil">Brazil</Option>
  <Option value="brunei">Brunei</Option>
  <Option value="bulgaria">Bulgaria</Option>
  <Option value="burkina_faso">Burkina Faso</Option>
  <Option value="burundi">Burundi</Option>
  <Option value="cabo_verde">Cabo Verde</Option>
  <Option value="cambodia">Cambodia</Option>
  <Option value="cameroon">Cameroon</Option>
  <Option value="canada">Canada</Option>
  <Option value="central_african_republic">Central African Republic</Option>
  <Option value="chad">Chad</Option>
  <Option value="chile">Chile</Option>
  <Option value="china">China</Option>
  <Option value="colombia">Colombia</Option>
  <Option value="comoros">Comoros</Option>
  <Option value="congo">Congo</Option>
  <Option value="costa_rica">Costa Rica</Option>
  <Option value="croatia">Croatia</Option>
  <Option value="cuba">Cuba</Option>
  <Option value="cyprus">Cyprus</Option>
  <Option value="czech_republic">Czech Republic</Option>
  <Option value="denmark">Denmark</Option>
  <Option value="djibouti">Djibouti</Option>
  <Option value="dominica">Dominica</Option>
  <Option value="dominican_republic">Dominican Republic</Option>
  <Option value="ecuador">Ecuador</Option>
  <Option value="egypt">Egypt</Option>
  <Option value="el_salvador">El Salvador</Option>
  <Option value="equatorial_guinea">Equatorial Guinea</Option>
  <Option value="eritrea">Eritrea</Option>
  <Option value="estonia">Estonia</Option>
  <Option value="eswatini">Eswatini</Option>
  <Option value="ethiopia">Ethiopia</Option>
  <Option value="fiji">Fiji</Option>
  <Option value="finland">Finland</Option>
  <Option value="france">France</Option>
  <Option value="gabon">Gabon</Option>
  <Option value="gambia">Gambia</Option>
  <Option value="georgia">Georgia</Option>
  <Option value="germany">Germany</Option>
  <Option value="ghana">Ghana</Option>
  <Option value="greece">Greece</Option>
  <Option value="grenada">Grenada</Option>
  <Option value="guatemala">Guatemala</Option>
  <Option value="guinea">Guinea</Option>
  <Option value="guinea_bissau">Guinea-Bissau</Option>
  <Option value="guyana">Guyana</Option>
  <Option value="haiti">Haiti</Option>
  <Option value="honduras">Honduras</Option>
  <Option value="hungary">Hungary</Option>
  <Option value="iceland">Iceland</Option>
  <Option value="india">India</Option>
  <Option value="indonesia">Indonesia</Option>
  <Option value="iran">Iran</Option>
  <Option value="iraq">Iraq</Option>
  <Option value="ireland">Ireland</Option>
  <Option value="israel">Israel</Option>
  <Option value="italy">Italy</Option>
  <Option value="jamaica">Jamaica</Option>
  <Option value="japan">Japan</Option>
  <Option value="jordan">Jordan</Option>
  <Option value="kazakhstan">Kazakhstan</Option>
  <Option value="kenya">Kenya</Option>
  <Option value="kiribati">Kiribati</Option>
  <Option value="korea_north">North Korea</Option>
  <Option value="korea_south">South Korea</Option>
  <Option value="kosovo">Kosovo</Option>
  <Option value="kuwait">Kuwait</Option>
  <Option value="kyrgyzstan">Kyrgyzstan</Option>
  <Option value="laos">Laos</Option>
  <Option value="latvia">Latvia</Option>
  <Option value="lebanon">Lebanon</Option>
  <Option value="lesotho">Lesotho</Option>
  <Option value="liberia">Liberia</Option>
  <Option value="libya">Libya</Option>
  <Option value="liechtenstein">Liechtenstein</Option>
  <Option value="lithuania">Lithuania</Option>
  <Option value="luxembourg">Luxembourg</Option>
  <Option value="madagascar">Madagascar</Option>
  <Option value="malawi">Malawi</Option>
  <Option value="malaysia">Malaysia</Option>
  <Option value="maldives">Maldives</Option>
  <Option value="mali">Mali</Option>
  <Option value="malta">Malta</Option>
  <Option value="marshall_islands">Marshall Islands</Option>
  <Option value="mauritania">Mauritania</Option>
  <Option value="mauritius">Mauritius</Option>
  <Option value="mexico">Mexico</Option>
  <Option value="micronesia">Micronesia</Option>
  <Option value="moldova">Moldova</Option>
  <Option value="monaco">Monaco</Option>
  <Option value="mongolia">Mongolia</Option>
  <Option value="montenegro">Montenegro</Option>
  <Option value="morocco">Morocco</Option>
  <Option value="mozambique">Mozambique</Option>
  <Option value="myanmar">Myanmar</Option>
  <Option value="namibia">Namibia</Option>
  <Option value="nauru">Nauru</Option>
  <Option value="nepal">Nepal</Option>
  <Option value="netherlands">Netherlands</Option>
  <Option value="new_zealand">New Zealand</Option>
  <Option value="nicaragua">Nicaragua</Option>
  <Option value="niger">Niger</Option>
  <Option value="nigeria">Nigeria</Option>
  <Option value="north_macedonia">North Macedonia</Option>
  <Option value="norway">Norway</Option>
  <Option value="oman">Oman</Option>
  <Option value="pakistan">Pakistan</Option>
  <Option value="palau">Palau</Option>
  <Option value="panama">Panama</Option>
  <Option value="papua_new_guinea">Papua New Guinea</Option>
  <Option value="paraguay">Paraguay</Option>
  <Option value="peru">Peru</Option>
  <Option value="philippines">Philippines</Option>
  <Option value="poland">Poland</Option>
  <Option value="portugal">Portugal</Option>
  <Option value="qatar">Qatar</Option>
  <Option value="romania">Romania</Option>
  <Option value="russia">Russia</Option>
  <Option value="rwanda">Rwanda</Option>
  <Option value="saint_kitts_and_nevis">Saint Kitts and Nevis</Option>
  <Option value="saint_lucia">Saint Lucia</Option>
  <Option value="saint_vincent_and_the_grenadines">Saint Vincent and the Grenadines</Option>
  <Option value="samoa">Samoa</Option>
  <Option value="san_marino">San Marino</Option>
  <Option value="sao_tome_and_principe">Sao Tome and Principe</Option>
  <Option value="saudi_arabia">Saudi Arabia</Option>
  <Option value="senegal">Senegal</Option>
  <Option value="serbia">Serbia</Option>
  <Option value="seychelles">Seychelles</Option>
  <Option value="sierra_leone">Sierra Leone</Option>
  <Option value="singapore">Singapore</Option>
  <Option value="slovakia">Slovakia</Option>
  <Option value="slovenia">Slovenia</Option>
  <Option value="solomon_islands">Solomon Islands</Option>
  <Option value="somalia">Somalia</Option>
  <Option value="south_africa">South Africa</Option>
  <Option value="south_sudan">South Sudan</Option>
  <Option value="spain">Spain</Option>
  <Option value="sri_lanka">Sri Lanka</Option>
  <Option value="sudan">Sudan</Option>
  <Option value="suriname">Suriname</Option>
  <Option value="sweden">Sweden</Option>
  <Option value="switzerland">Switzerland</Option>
  <Option value="syria">Syria</Option>
  <Option value="taiwan">Taiwan</Option>
  <Option value="tajikistan">Tajikistan</Option>
  <Option value="tanzania">Tanzania</Option>
  <Option value="thailand">Thailand</Option>
  <Option value="timor_leste">Timor-Leste</Option>
  <Option value="togo">Togo</Option>
  <Option value="tonga">Tonga</Option>
  <Option value="trinidad_and_tobago">Trinidad and Tobago</Option>
  <Option value="tunisia">Tunisia</Option>
  <Option value="turkey">Turkey</Option>
  <Option value="turkmenistan">Turkmenistan</Option>
  <Option value="tuvalu">Tuvalu</Option>
  <Option value="uganda">Uganda</Option>
  <Option value="ukraine">Ukraine</Option>
  <Option value="united_arab_emirates">United Arab Emirates</Option>
  <Option value="united_kingdom">United Kingdom</Option>
  <Option value="united_states">United States</Option>
  <Option value="uruguay">Uruguay</Option>
  <Option value="uzbekistan">Uzbekistan</Option>
  <Option value="vanuatu">Vanuatu</Option>
  <Option value="vatican">Vatican</Option>
  <Option value="venezuela">Venezuela</Option>
  <Option value="vietnam">Vietnam</Option>
  <Option value="yemen">Yemen</Option>
  <Option value="zambia">Zambia</Option>
  <Option value="zimbabwe">Zimbabwe</Option>
</Select>
            </Form.Item>
          </Col>
}
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Enter Password" }]}
            >
              <Input.Password
                className="h-12"
                placeholder="Please Enter Password"
              />
            </Form.Item>
          </Col>
        </Row>

        {
  isMember === false ? null :
  <>
      <Row gutter={16}>
      <Col span={24}>
        <Form.Item
        name="is_priest"
        label="Are you a Priest? (Click the button below)"
          valuePropName="checked"
        >
          <div
            className={`relative inline-flex items-center h-6 w-12 rounded-full cursor-pointer transition-all duration-300 ${
              isPriest ? "bg-blue-500" : "bg-gray-300"
            }`}
            onClick={() => setIsPriest(!isPriest)}
          >
            <span
              className={`inline-block h-5 w-5 bg-white rounded-full transform transition-transform duration-300 ${
                isPriest ? "translate-x-6" : "translate-x-1"
              }`}
            ></span>
          </div>
        </Form.Item>
      </Col>
    </Row>

      {isPriest && (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="posted"
                label="Are you Posted?"
                rules={[{ required: true, message: "Please select Posted status" }]}
              >
                <Select
                  placeholder="Select Posted Status"
                  onChange={(value) => {
                    form.setFieldsValue({ posted: value });
                    setIsPosted(value);
                  }}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="pew_position"
                label="Current Position/Office in Priesthood"
                rules={[{ required: true, message: "Please enter your Current Position/Office in Priesthood" }]}
              >
                <Input placeholder="Please enter your Current Position/Office in Priesthood" />
              </Form.Item>
            </Col>
          </Row>

          {isPosted === "yes" && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="station_posted_to"
                  label="Station Posted To"
                  rules={[
                    { required: true, message: "Please enter Station Posted To" },
                  ]}
                >
                  <Input placeholder="Enter Station Posted To" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="previous_stations"
                  label="Previous Stations"
                  rules={[
                    { required: true, message: "Please enter Previous Stations" },
                  ]}
                >
                  <Input placeholder="Enter Previous Stations" />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="qualifications"
                label="Qualifications"
                rules={[{ required: true, message: "Please enter your Qualifications" }]}
              >
                <Select
                  placeholder="Select if you have Qualifications"
                  onChange={(value) => {
                    form.setFieldsValue({ qualifications: value });
                    setQualifications(value);
                  }}
                >
                  <Select.Option value="yes">Yes</Select.Option>
                  <Select.Option value="no">No</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="next_of_kin"
                label="Next of kin's contact"
                rules={[{ required: true, message: "Please enter your Next of Kin contact" }]}
              >
                <Input placeholder="Enter Next of Kin contact" />
              </Form.Item>
            </Col>
          </Row>

          {qualifications === "yes" && (
            <Row gutter={16}>
              <Col span={12}>
              <Form.Item
                name="qualificationsList"
                label="List Your Qualifications"
                rules={[{ required: true, message: "Please enter your Qualifications" }]}
              >
                <Input placeholder="Example: Waec, BSC, Masters, PHD etc..." />
              </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item
                  name="qualification_documents"
                  label="Qualification Documents"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e && e.fileList}
                  rules={[{ required: true, message: "Please upload your documents" }]}
                >
                  <Upload
                    action="/upload"
                    listType="picture"
                    multiple
                  >
                    <Button icon={<UploadOutlined />}>Upload Documents</Button>
                  </Upload>
                </Form.Item>
              </Col> */}
              
            </Row>
          )}

          {/* <Row gutter={16}>
           
          </Row> */}
        </>
      )}
  </>
}


<Row gutter={16}>
      <Col span={24}>
        <Form.Item
          label="Are you an Office Holder /Priest/Staff in any BCS Establishments/Departments/Enterprises(Click the button below)"
          name="is_stakeholder"
          valuePropName="checked"
        >
          <div
            className={`relative inline-flex items-center h-6 w-12 rounded-full cursor-pointer transition-all duration-300 ${
              isStakeholder ? "bg-blue-500" : "bg-gray-300"
            }`}
            onClick={() => setIsStakeholder(!isStakeholder)}
          >
            <span
              className={`inline-block h-5 w-5 bg-white rounded-full transform transition-transform duration-300 ${
                isStakeholder ? "translate-x-6" : "translate-x-1"
              }`}
            ></span>
          </div>
        </Form.Item>
      </Col>
    </Row>
    {isStakeholder && (
    <Collapse>
      <Collapse.Panel header={<span style={{ fontWeight: 'bold', color: '#1890ff' }}>Click here to continue</span>}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="officer_at"
                  label="Select Your Formation Level"
                  rules={[{ required: true, message: 'Enter Officer At' }]}
                >
                  <Select className='h-12' placeholder="Select Office">
                    {categories.map((category) => (
                      <Option key={category} value={category}>
                        {category}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="year_assumed"
                  label="Month & Year Of Appointment"
                  rules={[{ required: true, message: 'Enter Year Assumed' }]}
                >
                  <Input type='date' className="h-12" placeholder="Month & Year Of Appointment" />
                </Form.Item>
              </Col>
            </Row>

    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          name="position"
          label="Click to select your Formation below(if not highlighted, input manually)"
          rules={[{ required: true, message: 'Enter Office' }]}
        >
          <AutoComplete
            className="h-12 w-full"
            placeholder="Select or enter your office"
            options={categorie.map((category) => ({ value: category }))}
            filterOption={(inputValue, option) =>
              option?.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="office"
          label="Enter your position in the office"
          rules={[{ required: true, message: "Please enter your position in the office" }]}
              >
                <Input className="h-12 w-full" placeholder="Enter your position in the office" />
              </Form.Item>
      </Col>
    </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="means_of_identification"
                label="Means of Identification"
                rules={[{ required: true, message: 'Select Means of Identification' }]}
              >
                <Radio.Group>
                  <Radio value="nin">NATIONAL ID OR NIN FOR NIGERIANS</Radio>
                  <Radio value="drivers_license">DRIVER'S LICENCE NUMBER</Radio>
                  <Radio value="international_passport_id">INTERNATIONAL PASSPORT NUMBER</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
          <Col span={24}>
              <Form.Item
                name="document_image"
                label="Upload Your Means Of Identification"
                rules={[{ required: true, message: 'Please upload your document' }]}
              >
                <Upload {...uploadProps} maxCount={1}>
                  <Button icon={<UploadOutlined />}>Upload Your Means Of Identification</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
    </Collapse.Panel>
  </Collapse>
          )}


            <Button
            className="bg-blue-500 text-white p-5 mt-5 font-bold rounded-lg"
            type="primary"
             htmlType="submit"
            icon={<PlusOutlined />}
            loading={loading}
          >
            {loading ? 'Registering...' : 'Register'}
          </Button>
      </Form>
    </div>
  );
};

export default Register;
