// import images from '../../public/images/images' 

 const images = [
  { id: "1", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F334.jpg?alt=media&token=3313008b-cede-4d0d-9264-2a3686df0ecf",  tag: "Holy Father" },
  { id: "2", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2FDSC_0101%20-%20Copy.JPG?alt=media&token=3c566bed-e6b8-4d53-95e3-80945cdde675", tag: "KOK" },
  { id: "3", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0009.jpeg?alt=media&token=03a99916-9edd-4b14-afad-0161798e9250", tag: "Bcs Members" },
  { id: "4", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2Fpops.jpeg?alt=media&token=53ccb68b-aa7d-41d3-b8a4-c7f1c02667bd", tag: "Holy Father" },
  { id: "5", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F6.png?alt=media&token=bf8b73cb-f76b-401e-bd02-c6b6ae056c95", tag: "KOK" },
  { id: "6", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F223.jpg?alt=media&token=08db80b2-f214-4887-8624-c8ea56862834", tag: "Bcs Members" },
  { id: "7", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0001.jpeg?alt=media&token=de71b79b-be43-4e22-bcf6-8963e5525fd8", tag: "Bcs Members" },
  { id: "8", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2FWeb.png?alt=media&token=4e11abaa-8d29-4d91-8322-2306357d5086", tag: "KOK" },
  { id: "9", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0008.jpeg?alt=media&token=650d1264-6124-47a9-b2f2-4ab28034bab1", tag: "Bcs Members" },
  { id: "10", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F15609263_1656578644640276_346278321_o.jpg?alt=media&token=f6c28f79-7f54-4dc8-a23d-d17c6b742b0f", tag: "Holy Father" },
  { id: "11", imageUrl:"https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F19599026_1714124838617036_4905401138846290813_n.jpg?alt=media&token=0aeafada-e4f2-482c-b853-e18d5a18855b", tag: "KOK" },
  { id: "12", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F0008.jpeg?alt=media&token=650d1264-6124-47a9-b2f2-4ab28034bab1", tag: "Bcs Members" },
  { id: "13", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F10698394_375528712624654_7051621203313503290_n.jpg?alt=media&token=ec826ed9-3f56-47b7-adbb-17eb1892028f", tag: "KOK" },
  { id: "14", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F111.jpg?alt=media&token=af589a4d-5818-424f-a5f8-79edf4b0a895", tag: "Bcs Members" },
  { id: "15", imageUrl:  "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F13962595_266657947054181_3500336600462079757_n.jpg?alt=media&token=f60fed5f-9c5e-4890-bd3b-1724161053dc", tag: "KOK" },
  { id: "16", imageUrl: "https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F2%20(2).jpg?alt=media&token=f6448806-8a4f-4486-8679-243b6f380179", tag: "Holy Father" },
]

export default images