import React, { useState } from "react";
import "./Slider.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { HiArrowCircleLeft, HiArrowCircleRight } from "react-icons/hi";
const images = [
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS%2F1.jpg?alt=media&token=5a2db6c0-1912-4c39-aa44-05dd9e420ef7' ,
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2FDSC_0322.JPG?alt=media&token=a70b5f2f-7866-4384-a3cf-0fc225f61d35' ,
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2FFB_IMG_1446725084588.jpg?alt=media&token=044f71b2-3a9f-4538-9fb2-909eb40a9293',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2FFB_IMG_1446725141388.jpg?alt=media&token=a25ba4b3-49f6-432b-9613-18cf0b4a568d',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2Ffacebook-20150119-204607.png?alt=media&token=827f1ee0-8cab-479a-80c6-0069cf1b1e95',
  // 'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2Ffacebook-20150322-133655.png?alt=media&token=fa80442c-4b96-4096-84cb-dbb5477cd126',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2Ffacebook-20150322-133736.png?alt=media&token=5ff393fc-96cc-4a56-9be9-368375f40856',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2Ffacebook-20150329-125757.png?alt=media&token=d317ca7e-4618-40a0-aaf9-d113b579f25a',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2Ffacebook-20150410-085944%20-%20Copy.png?alt=media&token=00ba0b94-b52e-42c6-a392-7b5a5816cb1f',
  // 'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2FFB_IMG_1453013902370.jpg?alt=media&token=0e4713cd-c930-4275-abc9-3d834006663b',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2FFB_IMG_1479580464128.jpg?alt=media&token=790f3b92-8dd7-41b6-80d8-fd86674f64dd',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2FFB_IMG_1486595074521.jpg?alt=media&token=979d4b00-1a9f-442e-ad42-821728272d10',
  // 'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2FFB_IMG_1492252025671.jpg?alt=media&token=853b3b93-3041-45a3-bb6a-113efb7bb702',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2Ffacebook-20150322-133736.png?alt=media&token=5ff393fc-96cc-4a56-9be9-368375f40856',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F4.png?alt=media&token=1a173f66-557a-467a-82af-d92feb23c995',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F2.png?alt=media&token=d1cbcec0-61f9-4ae5-885a-a7685318c6ea',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2FDSC_0201.JPG?alt=media&token=48ec2b2e-3526-4e7d-a3f6-dc8a7f9768b9',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F11218703_1024945140860005_4497979392648195311_n.jpg?alt=media&token=91b4ee9f-f9cd-4fd6-9af4-a1d31a227d76',
  'https://firebasestorage.googleapis.com/v0/b/bcs-public-portal.appspot.com/o/BCS_WEBITE_ASSETS2%2F19599026_1714124838617036_4905401138846290813_n.jpg?alt=media&token=0aeafada-e4f2-482c-b853-e18d5a18855b',
];


function Third() {
  const [data, setData] = useState({ img: "", i: 0 });

  const handleClick = (img, i) => {
    setData({ img, i });
  };

  const imgAction = (action) => {
    let i = data.i;
    if (action === "next-img") {
      setData({ img: images[i + 1], i: i + 1 });
    }
    if (action === "previous-img") {
      setData({ img: images[i - 1], i: i - 1 });
    }
    if (!action) {
      setData({ img: "", i: 0 });
    }
  };

  return (
    <>
      {data.img && (
        <div className="w-screen">
          <div
            style={{
              width: "80%",
              height: "80vh",
              // background: "#fff",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              marginTop: "-300px",
              zIndex: 200,
            }}
          >
            <AiOutlineCloseCircle
              onClick={() => imgAction()}
              className="cancles"
              class="cursor-pointer"
              style={{
                position: "absolute",
                top: "10px",
                color: "white",
                // background: "white",
                fontSize: 40,
                borderRadius: 40,
                marginLeft: "80%",
              }}
            />

            <HiArrowCircleLeft
                          class="cursor-pointer"
              style={{ color: "white", fontSize: 40 }}
              onClick={() => imgAction("previous-img")}
            />
            <img className="images" src={data.img} alt="" />
            <HiArrowCircleRight
                          class="cursor-pointer"
              style={{ color: "white", fontSize: 40 }}
              onClick={() => imgAction("next-img")}
            />
            <h1
              style={{
                marginTop: "470px",
                position: "absolute",
              }}
            >
              <a href="/Gallery" style={{ color: "cyan", textDecorationLine: "none" }}>
                See more in Gallery
              </a>
            </h1>
          </div>
        </div>
      )}
      <section className="sliders">
        <div className="slides-tracks">
          <div className="slides">
            {images.map((image, i) => (
              <img
                className="minis"
                key={i}
                src={image}
                onClick={() => handleClick(image, i)}
                alt=""
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Third;